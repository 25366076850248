import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import tw from 'twin.macro'
import styled from '@emotion/styled'
import { ReactSVG } from 'react-svg'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import SEO from '../components/Seo'

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        globalSettings {
          markets {
            markets {
              flag {
                localFile {
                  ...FlagImage
                }
              }
              name
              slug
              contactDetails
            }
          }
        }
      }
      file(name: { eq: "MH-LOGO-NORDIC-gray-on-white-RGB-01" }) {
        id
        publicURL
      }
    }
  `)

  const PageWrap = styled.div`
    ${tw`flex flex-col mx-auto items-center justify-center p-10`}
    height: 100vh;
    max-width: 1200px;
    font-weight: 300;
    font-family: 'Trebuchet MS', sans-serif;
    color: RGBA(140, 130, 121, 1);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `

  const LogoWrap = styled.div`
    ${tw`mb-24`}
    svg {
      width: 300px;
    }
  `

  const Footer = styled.div`
    ${tw`w-full text-right mx-4 my-6`}
    a, button {
      ${tw`ml-5 md:ml-10 text-xs md:text-sm`}
      max-width: 1200px;
    }
  `

  const MarketsWrap = styled.div`
    ${tw`flex flex-wrap text-xs md:text-sm mb-10`}
  `

  const Market = styled.div`
    ${tw`w-full md:w-1/3 text-center mb-5 flex justify-center`}

    .gatsby-image-wrapper {
      ${tw`rounded-full mb-3 ml-5 mr-1`}
      min-width: 80px;
      height: 80px;
    }
  `

  const ContactDetails = styled.div`
    ${tw`text-left mr-5 ml-1 sm:w-full md:w-64`}
  `

  return (
    <PageWrap>
      <SEO title="Contact" />
      <LogoWrap>
        <ReactSVG src={data.file.publicURL} />
      </LogoWrap>
      <MarketsWrap>
        {data?.wp.globalSettings.markets.markets.map(
          ({ flag, name, contactDetails }) => {
            const imageGatsbyData = getImage(
              flag?.localFile.childImageSharp.gatsbyImageData
            )

            return (
              <Market key={name}>
                <GatsbyImage image={imageGatsbyData} alt={name} />
                <ContactDetails
                  dangerouslySetInnerHTML={{ __html: contactDetails }}
                ></ContactDetails>
              </Market>
            )
          }
        )}
      </MarketsWrap>
      <Footer>
        <button
          type="button"
          onClick={() => {
            navigate(-1)
          }}
        >
          Go Back
        </button>
      </Footer>
    </PageWrap>
  )
}

export default ContactPage
